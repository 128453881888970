<template>
  <div class="editor">
    <editor-menu-bubble
      :editor="editor"
      v-slot="{ commands, isActive, menu }"
      class="background-translucid"
    >
      <div
        class="menu-bubble primary v-btn"
        :class="{ 'is-active': menu.isActive }"
        :style="`left: ${menu.left}px; bottom: ${5+menu.bottom}px;`"
      >
        <v-btn
          :class="{ 'is-active': isActive.bold() }"
          @click="commands.bold"
          depressed
          small
        >
          <v-icon>{{$icon('i.FormatBold')}}</v-icon>
        </v-btn>
        <v-btn
          :class="{ 'is-active': isActive.italic() }"
          @click="commands.italic"
          depressed
          small
          tile
        >
          <v-icon>{{$icon('i.FormatItalic')}}</v-icon>
        </v-btn>
        <v-btn
          :class="{ 'is-active': isActive.underline() }"
          @click="commands.underline"
          depressed
          small
        >
          <v-icon>{{$icon('i.FormatUnderline')}}</v-icon>
        </v-btn>
        <v-btn
          :class="{ 'is-active': isActive.strike() }"
          @click="commands.strike"
          depressed
          small
        >
          <v-icon>{{$icon('i.FormatStrikethroughVariant')}}</v-icon>
        </v-btn>
      </div>
    </editor-menu-bubble>
    <editor-content :editor="editor" />
  </div>
</template>

<script>
import { Editor, EditorContent, EditorMenuBubble, Extension } from 'tiptap'
import {
  Bold,
  Italic,
  Strike,
  Underline,
  HardBreak,
  History,
  Placeholder
} from 'tiptap-extensions'

export default {
  components: {
    EditorContent: EditorContent,
    EditorMenuBubble: EditorMenuBubble
  },
  data () {
    return {
      content: '',
      editor: new Editor({
        onUpdate: ({ getHTML, getJSON }) => this.$emit('input', this.cleanedContent(getHTML(), getJSON())),
        content: this.content,
        editorProps: {
          handleKeyDown: (v, e) => {
            this.$emit('keydown', e)
          }
        },
        extensions: [
          new Bold(),
          new Italic(),
          new Strike(),
          new Underline(),
          new HardBreak(),
          new History(),
          new Placeholder({
            emptyEditorClass: 'is-editor-empty',
            emptyNodeClass: 'is-empty',
            emptyNodeText: this.placeholder,
            showOnlyWhenEditable: true,
            showOnlyCurrent: true
          }),
          new class extends Extension {
            constructor(that) {
              super()
              this.that = that
            }

            keys () {
              return {
                Enter: (state, dispatch, view) => {
                  this.that.$emit('keydown', new KeyboardEvent('keydown', {
                    bubbles: true,
                    cancelable: true,
                    key: 'Enter'
                  }))
                  return false
                }
              }
            }
          }(this)
        ]
      })
    }
  },
  methods: {
    cleanedContent (html, json) {
      html = /^<p>\s*<\/p>$/m.test(html) ? html = '' : html
      this.content = { html, json }
      return this.content
    },
    focus () {
      this.editor.focus()
    }
  },
  beforeDestroy () {
    this.editor.destroy()
  },
  props: {
    placeholder: String,
    value: [Object, String]
  },
  watch: {
    value (v) {
      if (!this.lodash.isEqual(this.content, v)) {
        this.editor.setContent(v.html)
      }
    }
  }
}
</script>

<style lang="stylus" scoped >
.menu-bubble
  position absolute
  visibility hidden
  opacity 0
  z-index 20

.is-active
  visibility visible
  opacity 1

.editor >>> p
  margin 0

.editor >>> p.is-editor-empty:first-child::before
  content attr(data-empty-text)
  float left
  color #aaa
  pointer-events none
  height 0
  font-style italic
</style>
